import {
  ApplicationRef, Component, ComponentFactoryResolver, ElementRef, EventEmitter, Injector, Input, OnInit,
  ViewChild
} from '@angular/core';
import {Scene} from "../../models/scene";
import {HotspotItem} from "../../hotspot/hotspot-item";
import videojs from 'video.js';
import {UploadMediaService} from "../../services/upload-media.service";
import {Media} from "../../models/media.model";
import {UtilsService} from "../../services/utils.service";
import {StorageService} from "../../services/storage.service";
import {MatDialog, MatDialogRef} from "@angular/material";
import {RegularVideoDialogComponent} from "../regular-video-dialog/regular-video-dialog.component";
import {HotspotService} from "../../services/hotspot.service";
import {HotspotDialogComponent} from "../hotspot-dialog/hotspot-dialog.component";
declare const videojs: any;

@Component({
  selector: 'app-regular-video-player-in-dialog',
  templateUrl: './regular-video-player-in-dialog.component.html',
  styleUrls: ['./regular-video-player-in-dialog.component.scss']
})
export class RegularVideoPlayerInDialogComponent implements OnInit {
  @ViewChild('targetR', {static: true}) target: ElementRef;

  @Input() scene: Scene;
  @Input()dialogRef: MatDialogRef<RegularVideoDialogComponent>;

  @Input() options: {
    fluid: boolean,
    preload:string,
    aspectRatio: string,
    plugins:any,
    autoplay: boolean,
    sources: {
      src: string,
      type: string,
    }[],
    responsive:boolean,
    userActions: {hotkeys:boolean},
    techOrder:[string],
    crossorigin:string,
    hotspots:HotspotItem[]

  };
  isMedia = true;


  player: videojs.Player;
  mediaFiles;


  constructor(private uploadService: UploadMediaService, private dataService:StorageService,private componentFactoryResolver: ComponentFactoryResolver, private hotsptService:HotspotService,private injector: Injector,private app: ApplicationRef,public dialog: MatDialog) {


  }

  ngOnInit() {

    let thisDialogRef = this.dialogRef;
    this.options.sources = [];
    this.options.sources.push({src:this.scene.media.src,type:"video/mp4"});

    //let thisCurrentHSIndex = this.currentHotspotIndex;
    let thisComponentFactoryResolver = this.componentFactoryResolver;
    let thisInjector = this.injector;
    let thisAppRef = this.app;
    let thisHotspotService = this.hotsptService;
    let thisDialog = this.dialog;

    let thisHotspots =  this.hotsptService.createAdvancedHotspots(this.scene.hotspots);

    this.hotsptService.hotspots.subscribe((result)=>{
      console.log("HOTSPOT UPDATED!!!");
      thisHotspots = result;
    });



    this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {
      let thisPlayer = this;

      this.on('timeupdate', () => {

        //add hotspots to the video autometically
        thisHotspots.some((entry,index) => {
          //console.log(this.currentTime());
          //console.log(entry.data.time);
          if (this.currentTime()>entry.data.time && this.currentTime()<entry.data.time+1 && !entry.data.isShown){
            thisHotspots[index].data.isShown=true;
            setTimeout(function() { thisPlayer.pause(); }, 100);
            //add hotspot

            // thisPlayer.pnlmViewer.addHotSpot ({
            //   "pitch": thisPlayer.pnlmViewer.getPitch(),     // Position Y
            //   "yaw": thisPlayer.pnlmViewer.getYaw(),         // Position Z
            //   "cssClass": "hotspot_class",            // CSS Class
            //   "createTooltipFunc": function(hotspotDiv,args){
                console.log("add hotspot");

            let data = thisHotspots[index].data;
            data.player = thisPlayer;
            const dialogRef = thisDialog.open(HotspotDialogComponent,{
              data
            });

            dialogRef.afterClosed().subscribe(result=>{
              console.log("Hotspot Dialog closed");

               if(result !=undefined){
                 //close regular video player
                 thisDialogRef.close(result);

               }
               else{
                 //TODO:CHECK THIS!!
                 thisPlayer.play();
               }

            });



                //dinamocally generated/added hotspot component
                /*let hotspotDivEl = document.getElementsByClassName("hotspot_class");
                let divNode = document.createElement('div');
                divNode.setAttribute('class','apphotsp');
                hotspotDivEl.item(0).appendChild(divNode);

                let componentFactory = thisComponentFactoryResolver.resolveComponentFactory(thisHotspots[index].component);
                const ref = componentFactory.create(thisInjector,[],divNode);

                thisHotspots[index].data.player = thisPlayer;

                ref.instance.data = thisHotspots[index].data;
                thisAppRef.attachView(ref.hostView);*/



          }
        })


      });

      this.on('ended', function() {
        //close dialog
        thisDialogRef.close();
        //this.dispose();
      });
    });


  }


  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }

}
