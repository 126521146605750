import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UtilsService} from "./utils.service";

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  public video:any;

  constructor(private http: HttpClient) { }


  getVideos(){
    return this.http.get(UtilsService.SERVER_URL+'/videos/load')
  }

  addVideo(data){

  }

  setVideo(video){
    localStorage.setItem('video',JSON.stringify(video));

    this.video = video;
  }

  getVideo(){
    console.log(localStorage.getItem('video'));
    let vd = localStorage.getItem('video');
    if(vd){
      return JSON.parse(vd);
    }
    else{
      return this.video;
    }
  }

  sendVideo(name,scenes){
    return this.http.post<any>(UtilsService.SERVER_URL+'/videos/add', {name:name, scenes:scenes})
  }

  loadVideos(){

  }

  deleteVideo(videoId){
    return this.http.post<any>(UtilsService.SERVER_URL+'/videos/remove', {id:videoId})
  }

}
