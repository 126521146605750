import {Hotspot} from "./hotspot.model";
import {Media} from "./media.model";
/**
 * Created by asoadmin on 2021-05-18.
 */

export class Scene {
  name: string;
  order:number;
  startTime:number;
  hotspots:Hotspot[];
  media:Media;
  id:string;


  constructor(
    name?: string,
    order?:number,
    startTime?:number,
    id?:string,
    hotspots?:Hotspot[],
    media?:Media

  ) {
    this.name = name;
    this.order = order;
    this.startTime = startTime;
    this.media = media;
    this.id = id;
    this.hotspots = hotspots;

  }


}
