import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs/index";
import {Scene} from "../models/scene";
import {Hotspot} from "../models/hotspot.model";
import {initData} from "ngx-bootstrap/positioning/modifiers";
import {Video} from "../models/video";
import {toInt} from "ngx-bootstrap/chronos/utils/type-checks";
import {Media} from "../models/media.model";
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public  scenes = new BehaviorSubject<Array<Scene>>([]);
  public hotspots = new BehaviorSubject<Array<Hotspot>>([]);

  public selectedSceneIndex = new BehaviorSubject(0);

  public selectedMedia = new BehaviorSubject<Media>(new Media());

  public previewMode = new BehaviorSubject(false);

  public dummyVariable = true;

  public playerTime = new BehaviorSubject(0);


  public switchScene = new BehaviorSubject({sceneId:'0',time:0});

  constructor() {
      //this.loadLocalData();

  }


  //load data from localStorage
  loadLocalData(){

    if(localStorage.hasOwnProperty("scenes")){
        //load data from local storage
       this.scenes.next(JSON.parse(localStorage.getItem("scenes")));
       this.selectedSceneIndex.next(parseInt(localStorage.getItem("sceneIndex")));
       //this.selectedMedia.next(JSON.parse(localStorage.getItem("selectedMedia")));
      //enable preview mode!!!


      if(this.dummyVariable){
        console.log(this.hasMedia(this.scenes.getValue()));
        if(this.hasMedia(this.scenes.getValue())){
          this.previewMode.next(true);
          this.dummyVariable = false;
        }

      }

    }
    else{
      //init data with new video
      this.initData();
    }
  }

  hasMedia(scences){

    let medias = scences.map(s => {
      let medias = [];
      if(s.media!=undefined){
        medias.push({src: s.media.src, type: "video/mp4"});
      }
      return medias;
    });

    if(medias.length>1){
      return true;
    }
    else{
      return false;
    }
  }

  initData(){
    this.scenes.next([new Scene("Start Scene",1,0,uuid.v4())]);
    localStorage.setItem("scenes",JSON.stringify(this.scenes.getValue()));
    localStorage.setItem("sceneIndex",this.selectedSceneIndex.getValue().toString());

  }

  setMedia(media){

      this.selectedMedia.next(media);
      this.scenes.getValue()[this.selectedSceneIndex.getValue()].media = media;
      localStorage.setItem("scenes",JSON.stringify(this.scenes.getValue()));
      this.scenes.next(this.scenes.getValue());
      console.log("MEDIA SAVED in SCENE!!!");

      //enable preview mode!!!
    if(this.dummyVariable){
      this.previewMode.next(true);
      this.dummyVariable = false;
    }

  }


  clearStorage(){
    localStorage.removeItem("scenes");
    localStorage.removeItem("sceneIndex");
    this.previewMode.next(false);
    this.dummyVariable = true;
  }



  addScene(){
    const currentValue = this.scenes.value;
    const updatedValue = [...currentValue, new Scene("Scene" +currentValue.length ,currentValue.length+1,0,uuid.v4())];
    this.updateScene(updatedValue);
    // this.scenes.next(updatedValue);
    // this.selectedSceneIndex.next(updatedValue.length-1);
    // localStorage.setItem("scenes",JSON.stringify(this.scenes.getValue()));

  }

  updateScene(newScenes){
    this.scenes.next(newScenes);
    this.selectedSceneIndex.next(newScenes.length-1);
    localStorage.setItem("scenes",JSON.stringify(this.scenes.getValue()));
  }

  removeScene(sceneId){
    //1. remove scence from the list
    const currentValue = this.scenes.value;
    const deleteIndex = currentValue.findIndex((scene)=>{
      return scene.id===sceneId;
    });
    currentValue.splice(deleteIndex, 1);
    //2. set new selected scene is previous scene
    //3. update scene in the localstorage
    this.updateScene(currentValue)
  }

  updateSceneOrder(previousIndex,currentIndex){
    //update the order
    this.scenes.value[previousIndex].order = previousIndex+1;
    this.scenes.value[currentIndex].order = currentIndex+1;
    this.updateScene(this.scenes.value);
  }

  updateHotspotsInScene(hotspot){
    hotspot.id = uuid.v4();
    if(this.scenes.value[this.selectedSceneIndex.value].hotspots!=undefined){
      this.scenes.value[this.selectedSceneIndex.value].hotspots.push(hotspot);
    }
    else{
      this.scenes.value[this.selectedSceneIndex.value].hotspots = [];
      this.scenes.value[this.selectedSceneIndex.value].hotspots.push(hotspot);
    }

    localStorage.setItem("scenes",JSON.stringify(this.scenes.getValue()));
    this.scenes.next(this.scenes.getValue());

    //update the hotspots in HotspotService

  }

  removeHotpost(id,index){
    //1. remove hotspot from the list in scence object

    const currentValue = this.scenes.value[this.selectedSceneIndex.value].hotspots;
    const deleteIndex = currentValue.findIndex((htp)=>{
      return htp.id===id;
    });
    this.scenes.value[this.selectedSceneIndex.value].hotspots.splice(deleteIndex, 1);
    this.scenes.next(this.scenes.getValue());
    localStorage.setItem("scenes",JSON.stringify(this.scenes.getValue()));

    console.log(this.scenes.value);
  }

  setMediTyep(type){
    this.scenes.value[this.selectedSceneIndex.value].media.type = type;
    this.scenes.next(this.scenes.getValue());
    localStorage.setItem("scenes",JSON.stringify(this.scenes.getValue()));
  }

  getScenes(){
    return this.scenes.value;
  }


  setScenes(scenes){
    this.scenes.next(scenes);
    this.selectedSceneIndex.next(0);
    localStorage.setItem("scenes",JSON.stringify(this.scenes.getValue()));
    localStorage.setItem("sceneIndex",this.selectedSceneIndex.getValue().toString());

  }

  enablePreviewMode(){

  }


}
