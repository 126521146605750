import { Component, OnInit } from '@angular/core';
import * as pannellum from 'pannellum/build/pannellum.js';
import {ActivatedRoute, Router} from "@angular/router";
import {HotspotService} from "../services/hotspot.service";
import {VideoService} from "../services/video.service";

// declare var pannellum: any;

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})


export class ViewerComponent implements OnInit {
  // private pannelliumPlayer;
  video;
  hotspots;

  constructor(private router : Router,private route: ActivatedRoute, private hotspotService:HotspotService, private videoService:VideoService) {

        this.hotspotService.loadHotspotsConfigFile(this.videoService.getVideo().hotspotsUrl).subscribe(result=>{

          this.video = this.videoService.getVideo();
          this.hotspots = this.hotspotService.createHotspots(result);
        })

  }

  ngOnInit() {
    // this.pannelliumPlayer = pannellum.viewer('panoramaContainer',
    //   {
    //     "type": "equirectangular",
    //     "panorama": "https://pannellum.org/images/alma.jpg",
    //     "autoLoad": true,
    //     "compass": true
    //
    //   });

  }

  back(){
    this.router.navigate(['/viewer-login'])
  }

}
