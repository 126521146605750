import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {VideoService} from "../../services/video.service";
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {HotspotService} from "../../services/hotspot.service";
import {ErrorStateMatcher} from "@angular/material";
import {StorageService} from "../../services/storage.service";


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}



@Component({
  selector: 'app-videoform',
  templateUrl: './videoform.component.html',
  styleUrls: ['./videoform.component.scss']
})
export class VideoformComponent implements OnInit {
  videoForm: FormGroup;
  previewMode = false;
  hotspots=[];
  custom_matcher = new MyErrorStateMatcher();

  constructor(private router:Router,private videoService:VideoService,private fb: FormBuilder, private hotspotService:HotspotService, private dataSerice:StorageService) {

    this.createVideoForm();

  }

  ngOnInit() {
    this.dataSerice.loadLocalData();
    this.dataSerice.previewMode.subscribe((mode)=>{
      this.previewMode = mode;
    })
  }

  back(){
    this.dataSerice.clearStorage();
    this.router.navigate(['/video-list'])
  }


  createVideoForm(){
    this.videoForm = this.fb.group({
      name: ['', [Validators.required]],
      author: ['', [Validators.required]],
      url:['', [Validators.required]],
      description:['',],
      hotspots:['',],
      hotspotsConfig:['', [Validators.required]]

    });
  }

}
