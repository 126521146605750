import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.scss']
})
export class MainpageComponent implements OnInit {

  constructor(private router : Router) { }

  ngOnInit() {
  }

  watchVideo(){
    //navigate to login to video viewer
    this.router.navigate(['/viewer-login'])
  }

  createVideo(){
    console.log("here");
    //navigate to login to video editor
    this.router.navigate(['/editor-login'])
  }

}
