import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Observable} from "rxjs/index";
import {UploadMediaService} from "../../services/upload-media.service";
import {HttpEventType, HttpResponse} from "@angular/common/http";
import {Media} from "../../models/media.model";

@Component({
  selector: 'app-media-upload',
  templateUrl: './media-upload.component.html',
  styleUrls: ['./media-upload.component.scss']
})
export class MediaUploadComponent implements OnInit {



  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';

  @Output() mediaWasSelected = new EventEmitter<Media>();

  fileInfos?: Observable<any>;

  constructor(private uploadService: UploadMediaService) { }

  ngOnInit() {
    this.fileInfos = this.uploadService.getFiles();
  }

  selectFile(event: any): void {

      this.selectedFiles = event.target.files;

  }


  upload(): void {
    this.progress = 0;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.currentFile = file;
        this.uploadService.upload(this.currentFile).subscribe(
          (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round(100 * event.loaded / event.total);
            } else if (event instanceof HttpResponse) {
              this.message = event.body.message;
              this.fileInfos = this.uploadService.getFiles();

              this.mediaWasSelected.emit(new Media(event.body.name,event.body.path,event.body.type,event.body.size));

            }
          },
          (err: any) => {
            console.log(err);
            this.progress = 0;

            if (err.error && err.error.message) {
              this.message = err.error.message;
            } else {
              this.message = 'Could not upload the file!';
            }

            this.currentFile = undefined;
          });
      }

      this.selectedFiles = undefined;
    }
  }

}
