import { Component, OnInit } from '@angular/core';
import {ErrorStateMatcher} from "@angular/material";
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {StorageService} from "../../services/storage.service";
import {VideoService} from "../../services/video.service";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}



@Component({
  selector: 'app-publishing',
  templateUrl: './publishing.component.html',
  styleUrls: ['./publishing.component.scss']
})
export class PublishingComponent implements OnInit {

  custom_matcher = new MyErrorStateMatcher();
  error = '';
  publishingForm: FormGroup;

  feedback;
  constructor(private fb: FormBuilder, private dataService:StorageService,private videoService:VideoService) {
    this.publishingForm = this.fb.group({
      name: ['', [Validators.required]]
    });
  }

  ngOnInit() {
  }

  save(){
    if(this.publishingForm.valid){

      //scenes,name,duration
      console.log(this.publishingForm.value);
      console.log(this.dataService.getScenes());
      this.videoService.sendVideo(this.publishingForm.value.name,this.dataService.getScenes()).subscribe(result=>{

        this.feedback=result;
      })


    }
  }

}
