import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component, ComponentFactoryResolver, ContentChild, ElementRef, Host, HostBinding, Injector, Input, OnDestroy, OnInit,
  Renderer2,
  TemplateRef, ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import videojs from 'video.js';

import {HotspotComponent} from "../hotspot/hotspot.component";
import {HotspDirective} from "../hotsp.directive";
import {HotspotItem} from "../hotspot/hotspot-item";
import {HotspotInterface} from "../hotspot/add-hotspot";
import {HotspotService} from "../services/hotspot.service";
declare const videojs: any;


@Component({
  selector: 'app-vjs-player',
  templateUrl: './vjs-player.component.html',
  styleUrls: ['./vjs-player.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VjsPlayerComponent implements OnInit,OnDestroy{
  @ViewChild('target', {static: true}) target: ElementRef;
  hotspots: HotspotItem[];
  currentHotspotIndex = -1;
  @ViewChild(HotspDirective, {static: true}) hotspotHost: HotspDirective;



  @Input() options: {
    fluid: boolean,
    preload:string,
    aspectRatio: string,
    plugins:any,
    autoplay: boolean,
    sources: {
      src: string,
      type: string,
    }[],
    responsive:boolean,
    userActions: {hotkeys:boolean},
    techOrder:[string],
    hotspots:HotspotItem[]

  };
  player: videojs.Player;

  constructor(private elementRef: ElementRef,private componentFactoryResolver: ComponentFactoryResolver, private hotsptService:HotspotService,private renderer:Renderer2,private detectorRef:ChangeDetectorRef,private injector: Injector,private app: ApplicationRef) {


  }

  ngOnInit() {


    let thisHotspots = this.options.hotspots;
    let thisCurrentHSIndex = this.currentHotspotIndex;
    let thisComponentFactoryResolver = this.componentFactoryResolver;
    let thisInjector = this.injector;
    let thisAppRef = this.app;
    let thisHotService = this.hotsptService;

    //this.options.userActions.hotkeys = false;
    console.log(this.options);


      // instantiate Video.js
    this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {

      let thisPlayer = this;

      //Listen video timeline
      this.on('timeupdate', () => {
        thisHotspots = thisHotService.getHotspots();
        //console.log(thisHotspots);
        //add hotspots to the video autometically
        thisHotspots.some((entry,index) => {
          if (this.currentTime()>entry.data.time && this.currentTime()<entry.data.time+1 && !entry.data.isShown){
            thisHotspots[index].data.isShown=true;
            setTimeout(function() { thisPlayer.pause(); }, 100);
            //add hotspot
            thisPlayer.pnlmViewer.addHotSpot ({
              "pitch": thisPlayer.pnlmViewer.getPitch(),     // Position Y
              "yaw": thisPlayer.pnlmViewer.getYaw(),         // Position Z
              "cssClass": "hotspot_class",            // CSS Class
              "createTooltipFunc": function(hotspotDiv,args){

                //dinamocally generated/added hotspot component
                let hotspotDivEl = document.getElementsByClassName("hotspot_class");
                let divNode = document.createElement('div');
                divNode.setAttribute('class','apphotsp');
                hotspotDivEl.item(0).appendChild(divNode);

                let componentFactory = thisComponentFactoryResolver.resolveComponentFactory(thisHotspots[index].component);
                const ref = componentFactory.create(thisInjector,[],divNode);

                thisHotspots[index].data.player = thisPlayer;

                ref.instance.data = thisHotspots[index].data;
                thisAppRef.attachView(ref.hostView);



              },          // Function to be executed
              "type": "info",                            // Hotspot type
              "createTooltipArgs": "qA_02_20",           // Function argument(s)
              "id": thisHotspots[index].data.name                          // Hotspot id
            });


          }
        })



        //thisCurrentHSIndex = (thisCurrentHSIndex + 1) % thisHotspots.length;
        //let adHotspot = thisHotspots[thisCurrentHSIndex];
        /*if(this.currentTime() > 5.0 && this.currentTime() < 6.0 && !adHotspot.data.isShown){
          setTimeout(function() { thisPlayer.pause(); }, 100);
          // Disable play button for the group
          //playButton.style.display = "none";
          // Add Hotspot to Pannellum Plugin

          thisHotspots[thisCurrentHSIndex].data.isShown = true;


          thisPlayer.pnlmViewer.addHotSpot ({
            "pitch": thisPlayer.pnlmViewer.getPitch(),     // Position Y
            "yaw": thisPlayer.pnlmViewer.getYaw(),         // Position Z
            "cssClass": "hotspot_class",            // CSS Class
            "createTooltipFunc": function(hotspotDiv,args){
              console.log("here");
              //dinamocally generated/added hotspot component
              let hotspotDivEl = document.getElementsByClassName("hotspot_class");
              let divNode = document.createElement('div');
              divNode.setAttribute('class','apphotsp');
              hotspotDivEl.item(0).appendChild(divNode);

              let componentFactory = thisComponentFactoryResolver.resolveComponentFactory(adHotspot.component);
              const ref = componentFactory.create(thisInjector,[],divNode);
              thisAppRef.attachView(ref.hostView);



            },          // Function to be executed
            "type": "info",                            // Hotspot type
            "createTooltipArgs": "qA_02_20",           // Function argument(s)
            "id": "qA_02_20"                           // Hotspot id
          });
        }*/
      });

    });
     this.player.pannellum();



  }







  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }
}
