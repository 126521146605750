import { Component, OnInit } from '@angular/core';
import {ErrorStateMatcher} from "@angular/material";
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {AuthenticationService} from "../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-logineditor',
  templateUrl: './logineditor.component.html',
  styleUrls: ['./logineditor.component.scss']
})
export class LogineditorComponent implements OnInit {

  custom_matcher = new MyErrorStateMatcher();
  returnUrl: string;
  error = '';
  loginForm: FormGroup;

  constructor(private router : Router,private route: ActivatedRoute,private fb: FormBuilder,private authenticationService: AuthenticationService) {
    this.createLoginForm();
  }

  login() : void {
    this.router.navigate(['/video-list']);
    if(this.loginForm.valid){

      //send data
      /*this.authenticationService.login(this.loginForm.value.email,this.loginForm.value.password).subscribe(result=>{
       console.log(result);
       if(result["auth"]){
       //this.authenticationService.setSession(result);
       this.router.navigate(['/viewer']);
       }
       else{
       //TODO:show invalid message
       console.log("Error while login " + result);

       this.error = 'some error';
       }
       });*/

    }
  }

  createLoginForm(){
    this.loginForm = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }
  ngOnInit() {
  }
}
