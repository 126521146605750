import {
  AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2, TemplateRef,
  ViewContainerRef
} from '@angular/core';
import {HotspotService} from "./services/hotspot.service";

@Directive({
  selector: '.apphotsp'
})
export class HotspDirective implements OnInit{
//private viewContainerRef: ViewContainerRef,
  constructor(public viewContainerRef: ViewContainerRef) {
    console.log("directive constructor");
  }

  ngOnInit(){
    console.log("oninit");
  }


  //  constructor(vc: ViewContainerRef, el: ElementRef,htService:HotspotService){
  //    console.log("directive constructor");
  //    htService.setRefs(vc,el);
  //  }
  //  ngOnInit() {
  //
  //    console.log("ngoninit")
  //  }
  // ngAfterViewInit(): void {
  //   //this.renderer.addClass(this.elementRef.nativeElement, 'ui');
  //   //this.renderer.addClass(this.elementRef.nativeElement, 'button');
  //   //this.renderer.addClass(this.elementRef.nativeElement, this.appButton);
  // }

}
