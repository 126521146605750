import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {VplayerService} from "../../services/vplayer.service";
import {HotspotItem} from "../../hotspot/hotspot-item";
import {Hotspot} from "../../models/hotspot.model";
import {UploadMediaService} from "../../services/upload-media.service";
import {StorageService} from "../../services/storage.service";
import {Media} from "../../models/media.model";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {UtilsService} from "../../services/utils.service";

@Component({
  selector: 'app-video-editor',
  templateUrl: './video-editor.component.html',
  styleUrls: ['./video-editor.component.scss']
})
export class VideoEditorComponent implements OnInit {

  @ViewChild('target', {static: true}) target: ElementRef;

  highlightScene : Number;
  videoForm:FormGroup;
  selectedMedia;
  selectedHotspot;
  isNewHotspot;
  selectedScene;
  selectedMediaType = "spherical";
  mediaTypes;
  hotspots:Hotspot[];

  scenes = [];

  mediaFiles = [];


  constructor(public uploadService:UploadMediaService, public dataService:StorageService,private fb: FormBuilder) {

    this.mediaTypes = UtilsService.MediaTypes;

    this.videoForm = this.fb.group({
      scene:new FormControl(),
      media:new FormControl(),
      mediaType:new FormControl('spherical')
    });

    uploadService.getVideoFiles().subscribe((files)=>{
      this.mediaFiles = files;
      //console.log(this.mediaFiles);
      //get selected media if exist


      //this.setMedia(dataService.selectedMedia.getValue());
    });

    this.dataService.scenes.subscribe((data)=>{
      this.scenes = data;

      this.getSelectedScene();
    });



    //this.scenes = this.dataService.scenes.getValue();

  }

  onMediaTypeChange(event){

    this.selectedMediaType = event.value;
    this.dataService.setMediTyep(event.value);
  }

  getSelectedScene(){
    this.dataService.selectedSceneIndex.subscribe((index)=>{

      this.highlightScene = index;


      if(this.scenes[index] !=undefined){


      this.selectedScene = this.scenes[index].name;


      this.hotspots =this.scenes[index].hotspots;

      if(this.scenes[index].media!=undefined){

        this.selectedMedia = this.scenes[index].media;
        this.videoForm.controls['media'].setValue(this.selectedMedia.src);
        this.videoForm.controls['mediaType'].setValue(this.scenes[index].media.type);
      }
      else{
        this.videoForm.controls['media'].setValue("");
        this.videoForm.controls['mediaType'].setValue("");
        this.selectedMedia = null;
      }
      }

    });
  }

  ngOnInit() {
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.scenes, event.previousIndex, event.currentIndex);
    this.dataService.updateSceneOrder(event.previousIndex, event.currentIndex);
  }

  setMedia(media){

    this.selectedMedia = media;

    this.dataService.setMedia(new Media(media.name,media.src,this.selectedMediaType));

    this.uploadService.getVideoFiles().subscribe((files)=>{
      this.mediaFiles = files;
      this.videoForm.controls['media'].setValue(this.selectedMedia.src);
    });


  }

  showHotspotCreationForm(){
    this.isNewHotspot = true;
  }
  isHotspotCreated(event){
    this.isNewHotspot = false;
  }

  onMediaChange(media){

    let mediaFile = this.mediaFiles.filter((file)=>{
      return file.src===media.value;
    });

    if(mediaFile.length>0){
      this.selectedMedia = new Media(mediaFile[0].name,mediaFile[0].src,this.selectedMediaType);
      this.dataService.setMedia(new Media(mediaFile[0].name,mediaFile[0].src,this.selectedMediaType));

    }
    else{
      console.log("SOMETHING WRONG WITH SET SELECTED MEDIA!!!!")
    }
  }

  addScene(){
     //  create new video scene
     this.dataService.addScene();
  }


  selectScene(id,divIndex){

    //get index scene
    let index = this.scenes.findIndex((scene)=>{
      return scene.id===id;
    });
    this.dataService.selectedSceneIndex.next(index);

    this.highlightScene = divIndex;
    //this.selectedMedia = this.dataService.scenes.value[index].media;
  }

  removeScene(sceneId){
    this.dataService.removeScene(sceneId);
  }

  removeHotspot(hptId,index){
    this.dataService.removeHotpost(hptId,index);

  }

}
