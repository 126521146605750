import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-regular-video-hotspot',
  templateUrl: './regular-video-hotspot.component.html',
  styleUrls: ['./regular-video-hotspot.component.scss']
})
export class RegularVideoHotspotComponent implements OnInit {
  @Input() data: any;
  show = false;
  showQ = true;

  constructor() { }

  ngOnInit() {
  }

}
