import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "../../services/utils.service";
import {MatDialog} from "@angular/material";
import {MediaDialogComponent} from "../media-dialog/media-dialog.component";
import {StorageService} from "../../services/storage.service";
import {Hotspot} from "../../models/hotspot.model";

@Component({
  selector: 'app-hotspot-settings',
  templateUrl: './hotspot-settings.component.html',
  styleUrls: ['./hotspot-settings.component.scss']
})
export class HotspotSettingsComponent implements OnInit {

  @Output() isHotspotCreate = new EventEmitter<boolean>();

  htSettingForm: FormGroup;
  selectedType;
  selectedImage;
  selectedAction;
  videoScenes;
  hotspotTypes = UtilsService.HotspotTypes;
  hotspotActions = UtilsService.HotspotActions;

  questionOptions =[];
  currentHotspot = new Hotspot();

  currentPlayerTime;

  constructor(private fb: FormBuilder,public dialog: MatDialog, private dataService:StorageService) {

    this.dataService.playerTime.subscribe((time)=>{
      this.currentPlayerTime = time;
    });



    this.htSettingForm = this.fb.group({
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
      img: ['', ],
      weblink:['', ],
      text:['', ],
      option:['', ],
      isCorrect:['', ],
      time:[this.currentPlayerTime, ],
      switchScene:['false', ],
      feedbackA:['',],
      feedbackB:['',],
      videoSceneA:['', ],
      videoSceneB:['',],
      time_videoB:['',],
      time_videoA:['',],
      hasImage:['false',],
      endAction:['none',],
    });

  }

  typeChangeHandler(type){
    this.selectedType = type.value;
    this.currentHotspot.setType(type.value);
  }
  actionChangeHandler(action){
    this.selectedAction = action.value;
    this.currentHotspot.setEndAction(action.value);
  }

  videoAChangeHandler(sceneA){
      console.log(sceneA);
      this.currentHotspot.setVideoSceneA(sceneA.value);
  }

  videoBChangeHandler(sceneB){
     console.log(sceneB);
    this.currentHotspot.setVideoSceneB(sceneB.value);
  }


  addOption(){

    this.questionOptions.push({text:this.htSettingForm.value.option,correct:this.htSettingForm.value.isCorrect});
    //add it to hotspot
    this.currentHotspot.addAnswer(this.htSettingForm.value.option,this.htSettingForm.value.isCorrect);
    //clear the form fields
    this.htSettingForm.controls["option"].setValue("");
    this.htSettingForm.controls["isCorrect"].setValue(null);
  }
  ngOnInit() {
    this.dataService.scenes.subscribe((scenes)=>{
      this.videoScenes = scenes;
    })
  }


  createHotspot(){
    if(this.htSettingForm.valid){

      let formData = this.htSettingForm.value;
      this.currentHotspot.setName(formData.name);
      this.currentHotspot.setText(formData.text);
      this.currentHotspot.setTime(formData.time);
      this.currentHotspot.setTimeA(formData.time_videoA);
      this.currentHotspot.setTimeB(formData.time_videoB);
      this.currentHotspot.setFeedbackA(formData.feedbackA);
      this.currentHotspot.setFeedbackB(formData.feedbackB);
      this.currentHotspot.setEndAction(formData.endAction);
      this.currentHotspot.setWebLink(formData.weblink);

      //console.log(this.currentHotspot);
      //this.dataService.updateHotspots(this.currentHotspot);
      //update scene
      this.dataService.updateHotspotsInScene(this.currentHotspot);
      this.htSettingForm.reset();
      this.isHotspotCreate.emit(false);

    }
  }

  cancel(){
    this.htSettingForm.reset();
    this.isHotspotCreate.emit(false);
  }

  selectImage(){
    const dialogRef = this.dialog.open(MediaDialogComponent, {
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);
      this.selectedImage = result;
      this.currentHotspot.setImage(result);
    });

  }
}
