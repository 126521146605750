import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {UploadMediaService} from "../../services/upload-media.service";

@Component({
  selector: 'app-media-dialog',
  templateUrl: './media-dialog.component.html',
  styleUrls: ['./media-dialog.component.scss']
})
export class MediaDialogComponent implements OnInit {

  images;
  selectedFile;
  constructor(public dialogRef: MatDialogRef<MediaDialogComponent>, public mediaService:UploadMediaService) { }

  ngOnInit() {
    this.mediaService.getImageFiles().subscribe((result)=>{
        this.images = result;
        console.log(result);
    });

  }

  cancel(){
    this.dialogRef.close();
  }

  selectImage(image){
    this.selectedFile = image.url;
  }
}
