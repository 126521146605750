import {Scene} from "./scene";
/**
 * Created by asoadmin on 2021-05-12.
 */
export class Hotspot {
  name: string;
  type:string;
  text:string;
  time:number;
  img: string;
  id:number;
  weblink:string;
  answers: any[];
  switchSceneA: boolean;
  videoSceneA:Scene;
  switchSceneB: boolean;
  videoSceneB:Scene;
  startTimeA:number;
  startTimeB:number;
  endAction:string;
  switchVideo: boolean;
  switchCondition:any;
  feedbackA:string;
  feedbackB:string;

  constructor(
    name?: string,
    type?:string,
    text?:string,
    time?:number,
    img?: string,
    id?:number,
    weblink?:string,
    answers?: [any],
    videoSceneA?:Scene,
    videoSceneB?:Scene,
    startTimeA?:number,
    startTimeB?:number,
    switchSceneA?:boolean,
    switchSceneB?:boolean,
    switchVideo?: boolean,
    switchCondition?:any,
    endAction?:string,
    feedbackA?:string,
    feedbackB?:string
  ) {
    this.name = name;
    this.type = type;
    this.text = text;
    this.time = time;
    this.img = img;
    this.id = id;
    this.weblink = weblink;
    this.answers = answers;
    this.videoSceneA =videoSceneA;
    this.videoSceneB = videoSceneB;
    this.startTimeA = startTimeA;
    this.startTimeB = startTimeB;
    this.switchSceneA = switchSceneA;
    this.switchSceneB = switchSceneB;
    this.endAction = endAction;
    this.feedbackA=feedbackA;
    this.feedbackB = feedbackB;
  }

  addAnswer(text,isCorrect){
    if(this.answers != undefined){
      this.answers.push({"option":text,"isCorrect":isCorrect});
    }
    else{
      this.answers = [];
      this.answers.push({"option":text,"isCorrect":isCorrect});
    }

  }

  setVideoSceneA(id){
   this.videoSceneA = id;
   this.switchSceneA =true;
  }

  setVideoSceneB(id){
    this.videoSceneB = id;
    this.switchSceneB =true;
  }

  setImage(src){
    this.img = src;
  }

  setType(type){
    this.type = type;
  }

  setEndAction(action){
    this.endAction = action;
  }

  setName(name){
    this.name = name;
  }

  setText(text){
    this.text = text;
  }

  setWebLink(link){
    this.weblink = link;
  }
  setTime(time){
    this.time = time;
  }

  setTimeA(timeA){
    this.startTimeA = timeA;
  }

  setTimeB(timeB){
    this.startTimeB = timeB;
  }

  setFeedbackA(text){
    this.feedbackA = text;
  }

  setFeedbackB(text){
    this.feedbackB = text;
  }


}
