import {
  ApplicationRef,
  Component, ComponentFactoryResolver, ElementRef, Injector, Input, OnDestroy, OnInit,
  ViewChild
} from '@angular/core';
import {HotspotItem} from "../../hotspot/hotspot-item";
import videojs from 'video.js';
import {UploadMediaService} from "../../services/upload-media.service";
import {Media} from "../../models/media.model";
import {UtilsService} from "../../services/utils.service";
import {StorageService} from "../../services/storage.service";
import {HotspotService} from "../../services/hotspot.service";
import {HotspDirective} from "../../hotsp.directive";
declare const videojs: any;

@Component({
  selector: 'app-vjs-player-editor',
  templateUrl: './vjs-player-editor.component.html',
  styleUrls: ['./vjs-player-editor.component.scss']
})
export class VjsPlayerEditorComponent implements OnInit,OnDestroy {

  @ViewChild('target', {static: true}) target: ElementRef;
  @ViewChild(HotspDirective, {static: true}) hotspotHost: HotspDirective;
  @Input() media: Media;

  @Input() options: {
    fluid: boolean,
    preload:string,
    aspectRatio: string,
    plugins:any,
    autoplay: boolean,
    sources: {
      src: string,
      type: string,
    }[],
    responsive:boolean,
    userActions: {hotkeys:boolean},
    techOrder:[string],
    crossorigin:string,
    hotspots:HotspotItem[]

  };
  isMedia = true;


  player: videojs.Player;
  mediaFiles;

  scene;
  hotspots;

  constructor(private dataService:StorageService, private hotspotService:HotspotService,private componentFactoryResolver: ComponentFactoryResolver,private injector: Injector,private app: ApplicationRef,) {

    this.dataService.selectedSceneIndex.subscribe((index)=>{
      this.scene = this.dataService.scenes.getValue()[index];
      this.uptadeVideoSource();
      //console.log("HERE2!!!");
    });


  }


  uptadeVideoSource(){

    if(this.scene.media && this.player){
      const sources = [{src:this.scene.media.src,type:"video/mp4"}];
      this.player.src(sources);
    }

  }

  ngOnInit() {

    let self = this;
    this.options.sources = [];
    this.options.sources.push({src:this.media.src,type:"video/mp4"});

    let thisHotspots =  this.hotspotService.createAdvancedHotspots(this.scene.hotspots);


    this.dataService.scenes.subscribe((result)=>{

      this.scene = result[this.dataService.selectedSceneIndex.getValue()];
      thisHotspots =  this.hotspotService.createAdvancedHotspots(this.scene.hotspots);
      this.uptadeVideoSource();

    });



    // this.hotspotService.hotspots.subscribe((result)=>{
    //   console.log("NEW hotspot!!!");
    //   thisHotspots = result;
    //   console.log(thisHotspots);
    // });


    this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {
         let thisPlayer = this;

      this.on('timeupdate', () => {


        //add hotspots to the video autometically
        thisHotspots.some((entry,index) => {
          //console.log(this.currentTime());
          //console.log(entry.data.time);
          if (this.currentTime()>entry.data.time && this.currentTime()<entry.data.time+1 && !entry.data.isShown){
            thisHotspots[index].data.isShown=true;
            setTimeout(function() { thisPlayer.pause(); }, 100);
            //add hotspot
            thisPlayer.pnlmViewer.addHotSpot ({
              "pitch": thisPlayer.pnlmViewer.getPitch(),     // Position Y
              "yaw": thisPlayer.pnlmViewer.getYaw(),         // Position Z
              "cssClass": "hotspot_class",            // CSS Class
              "createTooltipFunc": function(hotspotDiv,args){

                //dinamocally generated/added hotspot component
                let hotspotDivEl = document.getElementsByClassName("hotspot_class");
                let divNode = document.createElement('div');
                divNode.setAttribute('class','apphotsp');
                hotspotDivEl.item(0).appendChild(divNode);

                let componentFactory = self.componentFactoryResolver.resolveComponentFactory(thisHotspots[index].component);
                const ref = componentFactory.create(self.injector,[],divNode);

                thisHotspots[index].data.player = thisPlayer;

                ref.instance.data = thisHotspots[index].data;
                self.app.attachView(ref.hostView);

              },          // Function to be executed
              "type": "info",                            // Hotspot type
              "createTooltipArgs": "qA_02_20",           // Function argument(s)
              "id": thisHotspots[index].data.name                          // Hotspot id
            });


          }
        })


      });


      this.on('pause',()=>{
        console.log("PAUSE!!!");
        self.dataService.playerTime.next(thisPlayer.currentTime());
      });



    });

    this.player.pannellum();

    this.dataService.selectedMedia.subscribe((media)=>{
      // console.log("MEDIA CHANGED");
      // console.log(media);

      if(media!=null && media.name!=undefined){

        const sources = [{src:media.src,type:"video/mp4"}];
        this.player.src(sources);
      }
      //this.player.play();
    })





  }


  uploadMedia(){

  }

  ngOnDestroy() {
    //console.log("PLAYER DESTROYED!!");
    // destroy player
    //if (this.player) {
     // this.player.dispose();
    //}
  }

}
