/**
 * Created by asoadmin on 2021-05-11.
 */
export enum MediaType{
  spherical, regular
}

export class Media {
    name: string;
    src:string;
    type:string;
    size:number;
    id:number;
    duration:number;


  constructor(
     name?: string,
     src?:string,
     type?:string,
     size?:number,
     id?:number,
     duration?:number
  ) {
    this.name = name;
    this.src = src;
    this.type = type;
    this.size = size;
    this.id = id;
    this.duration = duration;

  }
}
