import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs/index";
import {UtilsService} from "./utils.service";

@Injectable({
  providedIn: 'root'
})
export class UploadMediaService {

  constructor(private http: HttpClient) { }

  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', `${UtilsService.SERVER_URL}/files/upload`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  getFiles(): Observable<any> {
    return this.http.get(`${UtilsService.SERVER_URL}/files`);
  }

  getImageFiles(): Observable<any> {

    return this.http.get(`${UtilsService.SERVER_URL}/files/images`);
  }

  getVideoFiles(): Observable<any> {

    return this.http.get(`${UtilsService.SERVER_URL}/files/videos`);
  }

  streamVideo(filename){
    return this.http.get(`${UtilsService.SERVER_URL}/files/stream/` + filename);
  }
}
