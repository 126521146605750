import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UtilsService} from "./utils.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient) {
  }

  login(email:string, password:string ) {
    return this.http.post<any>(UtilsService.SERVER_URL+'/users/login', {email, password})

  }
}
