import {ComponentFactoryResolver, ElementRef, Injectable, ViewContainerRef} from '@angular/core';
import {HotspotItem} from "../hotspot/hotspot-item";
import {HotspotComponent} from "../hotspot/hotspot.component";
import {ComponentLoader} from "ngx-bootstrap/component-loader";
import {map} from "rxjs/internal/operators";
import {BehaviorSubject, from, Observable} from "rxjs/index";
import {HttpClient} from "@angular/common/http";
import { of } from 'rxjs'
import {HotspotTemplateComponent} from "../VideoEditor/hotspot-template/hotspot-template.component";
import {Hotspot} from "../models/hotspot.model";


@Injectable({
  providedIn: 'root'
})
export class HotspotService {

  // vc:ViewContainerRef;
  // el:ElementRef;
  hotspotList:HotspotItem[] = [];

  public hotspots = new BehaviorSubject<Array<HotspotItem>>([]);

  constructor(private http: HttpClient) { }

  // getHotspots(){
  //
  //    return [
  //      new HotspotItem(HotspotComponent, {name: 'Bombasto', bio: 'Brave as they come',id:'1', isShown:false}),
  //    ]
  // }

  loadHotspotsConfigFile(file){
    return this.http.get(file);
  }

  createHotspots(hotspots){

    for(let i =0;i<hotspots.length;i++){
      hotspots[i].isShown = false;
      this.hotspotList.push(new HotspotItem(HotspotComponent,hotspots[i]));

    }

    return this.hotspotList;
  }


  createAdvancedHotspots(hotspots){
    let someHotspots = [];

    if(hotspots!=undefined){


    for(let i =0;i<hotspots.length;i++){
      hotspots[i].isShown = false;
      someHotspots.push(new HotspotItem(HotspotTemplateComponent,hotspots[i]));

    }
    }
    this.hotspots.next(someHotspots);
    return this.hotspots.getValue();
  }



  setHotspots(file){
    this.http.get(file).subscribe((hotspots: any[])=>{

      this.hotspotList = [];
      for(let i =0;i<hotspots.length;i++){
        hotspots[i].isShown = false;
        this.hotspotList.push(new HotspotItem(HotspotComponent,hotspots[i]));

      }
    });
  }

  getHotspots():HotspotItem[]{
    return this.hotspotList;
  }

  getCurrentHotspots(){
    return this.hotspots.getValue();
  }

  // setViewRef(ref: ViewContainerRef){
  //   this.vc = ref;
  // }
  // getViewRef(){
  //   return this.vc;
  // }
  //
  // setRefs(vcRef: ViewContainerRef,elRef:ElementRef){
  //   this.vc =vcRef;
  //   this.el = elRef;
  // }
  // getElRef(){
  //   return this.el;
  // }


}
