import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {HotspotService} from "../../services/hotspot.service";
import {StorageService} from "../../services/storage.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-hotspot-dialog',
  templateUrl: './hotspot-dialog.component.html',
  styleUrls: ['./hotspot-dialog.component.scss']
})
export class HotspotDialogComponent implements OnInit {


  show = false;
  showQ = true;
  wrongAnswer=false;
  showFeedback = false;

  questionForm: FormGroup;
  freeTextForm: FormGroup;
  onClose = new EventEmitter();


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,fb: FormBuilder, public hotspotService: HotspotService, private dataService:StorageService,public dialogRef: MatDialogRef<HotspotDialogComponent>) {



    this.questionForm = fb.group({
      answers: new FormArray([])
    });

    this.freeTextForm = fb.group({
      freeText: ['',]
    });

  }

  ngOnInit() {

    this.addCheckboxes();
  }

  showQuestion() {
    //console.log("show question");
    this.show = true;
    this.showQ = false;

    this.dialogRef.updateSize('35%', '30%');
  }

  check() {
    if(this.data.type.indexOf("multichoice")!=-1){
      const selectedAnswers = this.questionForm.value.answers;
      const correctAnswers = this.data.answers.map(x => x.isCorrect);

      if (correctAnswers.toString().localeCompare(selectedAnswers.toString())===0) {
        this.wrongAnswer = true;
      }
      else {
        this.wrongAnswer = false;
      }

      this.showFeedback = true;
    }

  }

  checkWithStyle() {
    this.check();

    this.data.answers.forEach((item) => {
      if (item.isCorrect==='true') {
        item.color = 'green';
      }
      else {
        item.color = 'red';

      }

    });
  }
  private addCheckboxes() {
    if (this.data.answers) {
      this.data.answers.forEach(() => this.answersFormArray().push(new FormControl(false)));
    }
  }

  answersFormArray() {
    return this.questionForm.controls['answers'] as FormArray;
  }


  closeMultipleChoice() {

    setTimeout(() => {
      this.data.isShown = false;
    }, 3000);
    this.check();

    //remove hotspot from visualization
    //switch to another video scene


    if (this.data.endAction.localeCompare("switch")===0) {

      if(this.data.switchSceneA && this.wrongAnswer){
        console.log("Correct");
        this.swithVideo(this.data.videoSceneA,this.data.startTimeA)
      }
      else if(this.data.switchSceneB && !this.wrongAnswer){
        console.log("Wrong");
        this.swithVideo(this.data.videoSceneB,this.data.startTimeB)
      }
      else{
        this.data.player.play();
      }

    }
    else {
      this.data.player.play();
    }
    //remove the hotspot from video player
    //this.dialogRef.close();

  }

  private swithVideo(sceneId, videoTime){
    //get scence video
    let scenes = this.dataService.scenes.getValue();

    let scene = scenes.filter((scene)=>{
      return scene['id'].localeCompare(sceneId)===0;
    })[0];

    if(scene.media.type.localeCompare("regular")===0){


      const sources = [{ src: scene.media.src, type: 'video/mp4' }];

      this.data.player.src(sources);
      if(scene.hotspots !=undefined){
          this.hotspotService.createAdvancedHotspots(scene.hotspots);
      }
      if(videoTime != undefined){
          this.data.player.currentTime(videoTime)
      }
      this.dialogRef.close();
      //this.data.player.play();
    }
    else{
      //console.log({data:{sceneId:sceneId,time:videoTime}});
      this.dialogRef.close({sceneId:sceneId,time:videoTime});
    }

  }

  close(){
    setTimeout(() => {
      this.data.isShown = false;
    }, 3000);



    if (this.data.endAction.localeCompare("switch")===0) {
      this.swithVideo(this.data.videoSceneA,this.data.startTimeA)
    }
    else {
      this.dialogRef.close();
      this.data.player.play();
    }

  }

}
