import {
  ApplicationRef, Component, ComponentFactoryResolver, ElementRef, Injector, Input, OnInit,
  ViewChild, ViewEncapsulation
} from '@angular/core';
import {HotspotItem} from "../../hotspot/hotspot-item";
import {HotspDirective} from "../../hotsp.directive";
import {HotspotService} from "../../services/hotspot.service";
import videojs from 'video.js';
import {StorageService} from "../../services/storage.service";
import {Scene} from "../../models/scene";
import {MatDialog} from "@angular/material";
import {RegularVideoDialogComponent} from "../regular-video-dialog/regular-video-dialog.component";
import {ConfirmationDialogComponent} from "../confirmation-dialog/confirmation-dialog.component";
declare const videojs: any;
@Component({
  selector: 'app-videopreview',
  templateUrl: './videopreview.component.html',
  styleUrls: ['./videopreview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideopreviewComponent implements OnInit {

  @ViewChild('target', {static: true}) target: ElementRef;
  hotspots: HotspotItem[];
  currentHotspotIndex = -1;
  @ViewChild(HotspDirective, {static: true}) hotspotHost: HotspDirective;

  scenes;
  sceneIndex = 0;


  @Input() options: {
    fluid: boolean,
    preload:string,
    aspectRatio: string,
    plugins:any,
    autoplay: string,
    sources: {
      src: string,
      type: string,
    }[],
    responsive:boolean,
    enableSourceset:boolean,
    loop:boolean,
    techOrder:[string],
    hotspots:[HotspotItem]

  };
  player: videojs.Player;
  executed = false;
  medias;

  constructor(private elementRef: ElementRef,private componentFactoryResolver: ComponentFactoryResolver, private hotsptService:HotspotService,private injector: Injector,private app: ApplicationRef, private dataService:StorageService,public dialog: MatDialog) {
      this.dataService.scenes.subscribe((result)=>{
        this.scenes = result;

         this.medias = this.scenes.map(s => {
           let medias = [];
           if(s.media!=undefined){
             medias.push({src: s.media.src, type: "video/mp4"});
           }
           return medias;
         });



      });

  }

  ngOnInit() {
    this.initPlayer();

  }

  initPlayer(){
    let self = this;
    this.options.sources = this.medias;
    let currentScene = self.scenes[0];
    let thisHotspots =  this.hotsptService.createAdvancedHotspots(currentScene.hotspots);

    this.hotsptService.hotspots.subscribe((result)=>{
      thisHotspots = result;
    });


    // instantiate Video.js
    this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {

      let thisPlayer = this;

      //Listen video timeline
      this.on('timeupdate', () => {

        //add hotspots to the video autometically
        thisHotspots.some((entry,index) => {
          //console.log(this.currentTime());
          //console.log(entry.data.time);
          if (this.currentTime()>entry.data.time && this.currentTime()<entry.data.time+1 && !entry.data.isShown){
            thisHotspots[index].data.isShown=true;
            setTimeout(function() { thisPlayer.pause(); }, 100);
            //add hotspot
            thisPlayer.pnlmViewer.addHotSpot ({
              "pitch": thisPlayer.pnlmViewer.getPitch(),     // Position Y
              "yaw": thisPlayer.pnlmViewer.getYaw(),         // Position Z
              "cssClass": "hotspot_class",            // CSS Class
              "createTooltipFunc": function(hotspotDiv,args){

                //dinamocally generated/added hotspot component
                let hotspotDivEl = document.getElementsByClassName("hotspot_class");
                let divNode = document.createElement('div');
                divNode.setAttribute('class','apphotsp');
                hotspotDivEl.item(0).appendChild(divNode);

                let componentFactory = self.componentFactoryResolver.resolveComponentFactory(thisHotspots[index].component);
                const ref = componentFactory.create(self.injector,[],divNode);

                thisHotspots[index].data.player = thisPlayer;

                ref.instance.data = thisHotspots[index].data;
                self.app.attachView(ref.hostView);

              },          // Function to be executed
              "type": "info",                            // Hotspot type
              "createTooltipArgs": "qA_02_20",           // Function argument(s)
              "id": thisHotspots[index].data.name                          // Hotspot id
            });


          }
        })
      });


      this.on('ended', function() {
        self.playNextScene(thisPlayer);

      });

    });
    this.player.pannellum();

    this.dataService.switchScene.subscribe((result)=>{

      //get scence video
      if(result.sceneId.localeCompare('0')!=0){
        let scenes = this.dataService.scenes.getValue();

        let scene = scenes.filter((scene) => {
          return scene['id'].localeCompare(result.sceneId) === 0;
        })[0];

        console.log("REGULAR SCENE");
        //create new hotspot and show it in the player
        this.player.pause();

        //update hotspots!!!!
        this.hotsptService.createAdvancedHotspots(scene.hotspots);
        this.sceneIndex++;
        const dialogRef = this.dialog.open(RegularVideoDialogComponent,{
          data:{
            scene:scene
          }
        });

        dialogRef.afterClosed().subscribe(result=>{
          console.log("Regular  Video Dialog closed");
          console.log(result);
          if(result!=undefined){

            //switch to specific scene
            let currentScene = this.scenes.filter((scene)=>{
              return scene["id"].localeCompare(result.sceneId)===0;
            })[0];
            this.hotspots = this.hotsptService.createAdvancedHotspots(currentScene.hotspots);
            this.player.src(currentScene.media.src);
            this.player.play();
            this.executed = false;

          }
          else{
            this.playNextScene(this.player);
          }
        });
      }

    });
  }


   playNextScene(thisPlayer){
    console.log("HERE!!!!");
    console.log(this.executed);
     //if (!this.executed) {
       console.log("SCENE IS CHANGED SEQUENTIALLY");
       this.sceneIndex++;
       if(this.sceneIndex<this.medias.length){
         console.log(this.scenes[this.sceneIndex]);
         if(this.scenes[this.sceneIndex].media.type.localeCompare('spherical')===0){
           let currentScene = this.scenes[this.sceneIndex];
           this.hotspots = this.hotsptService.createAdvancedHotspots(currentScene.hotspots);
           thisPlayer.src(this.medias[this.sceneIndex]);
           thisPlayer.play();
           this.executed = true
         }
         else{
           console.log("REGULAR SCENE");
           //create new hotspot and show it in the player
           thisPlayer.pause();


           const dialogRef = this.dialog.open(RegularVideoDialogComponent,{
             data:{
               scene:this.scenes[this.sceneIndex]
             }
           });

           dialogRef.afterClosed().subscribe(result=>{
             console.log("Regular  Video Dialog closed");
             console.log(result);
             if(result!=undefined){

               //switch to specific scene
               let currentScene = this.scenes.filter((scene)=>{
                 return scene["id"].localeCompare(result.sceneId)===0;
               })[0];
               this.hotspots = this.hotsptService.createAdvancedHotspots(currentScene.hotspots);
               thisPlayer.src(currentScene.media.src);
               thisPlayer.play();
               this.executed = false;

             }
             else{

               this.playNextScene(thisPlayer);
             }



           });

         }


       }
       else{
         const dialogRef = this.dialog.open(ConfirmationDialogComponent);
       }




     //}
   }






  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }

}
