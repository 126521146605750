import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Scene} from "../../models/scene";

@Component({
  selector: 'app-regular-video-dialog',
  templateUrl: './regular-video-dialog.component.html',
  styleUrls: ['./regular-video-dialog.component.scss']
})
export class RegularVideoDialogComponent implements OnInit {

  scene:Scene;

  dialogRef: MatDialogRef<RegularVideoDialogComponent>
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private _dialogRef: MatDialogRef<RegularVideoDialogComponent>) {
   // console.log(data);
    this.scene = data.scene;
    this.dialogRef = _dialogRef;
  }

  ngOnInit() {
  }

}
