import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {HotspotItem} from "../../hotspot/hotspot-item";
import videojs from 'video.js';
import {UploadMediaService} from "../../services/upload-media.service";
import {Media} from "../../models/media.model";
import {UtilsService} from "../../services/utils.service";
import {StorageService} from "../../services/storage.service";
import {Scene} from "../../models/scene";
declare const videojs: any;

@Component({
  selector: 'app-regular-video-player',
  templateUrl: './regular-video-player.component.html',
  styleUrls: ['./regular-video-player.component.scss']
})
export class RegularVideoPlayerComponent implements OnInit {
  @ViewChild('targetR', {static: true}) target: ElementRef;

  @Input() media: Media;

  @Input() options: {
    fluid: boolean,
    preload:string,
    aspectRatio: string,
    plugins:any,
    autoplay: boolean,
    sources: {
      src: string,
      type: string,
    }[],
    responsive:boolean,
    userActions: {hotkeys:boolean},
    techOrder:[string],
    crossorigin:string,
    hotspots:HotspotItem[]

  };
  isMedia = true;


  player: videojs.Player;
  mediaFiles;

  constructor(private uploadService: UploadMediaService, private dataService:StorageService) {


  }

  ngOnInit() {

    this.options.sources = [];
    this.options.sources.push({src:this.media.src,type:"video/mp4"});
    //this.options.sources.push({src:UtilsService.SERVER_URL+"/files/stream/"+this.media.filename,type:"video/mp4"});


    this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {
      let thisPlayer = this;

      this.on('timeupdate', () => {
        //console.log(this.currentTime());

        // if (this.currentTime() > 5) {
        //   //switch to regular video
        //   const sources = [regularVideo];
        //   this.src(sources);
        //
        //   this.play();
        // }
      });
    });

    //this.player.pannellum();

    // this.dataService.selectedMedia.subscribe((media)=>{
    //   if(media!=null && media.name!=undefined){
    //     const sources = [{src:media.src,type:"video/mp4"}];
    //     this.player.src(sources);
    //   }
    //   //this.player.play();
    // })



  }


  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }

}
