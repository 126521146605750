import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {RouterModule, Routes} from "@angular/router";
import { ViewerComponent } from './viewer/viewer.component';
import {
  MatAutocompleteModule, MatButtonModule, MatCardModule, MatCheckboxModule, MatDialogModule, MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule, MatListModule, MatMenuModule, MatPaginatorModule, MatProgressSpinnerModule, MatRadioModule,
  MatSelectModule, MatTableModule,
  MatTabsModule,
  MatToolbarModule, MatTooltipModule
} from "@angular/material";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {AuthenticationService} from "./services/authentication.service";
import {UtilsService} from "./services/utils.service";
import { VjsPlayerComponent } from './vjs-player/vjs-player.component';
import { HotspotComponent } from './hotspot/hotspot.component';
import { HotspDirective } from './hotsp.directive';
import {HotspotService} from "./services/hotspot.service";
import { MainpageComponent } from './mainpage/mainpage.component';
import { VideolistComponent } from './VideoEditor/videolist/videolist.component';
import { LogineditorComponent } from './logineditor/logineditor.component';
import { VideoformComponent } from './VideoEditor/videoform/videoform.component';
import { VideopreviewComponent } from './VideoEditor/videopreview/videopreview.component';
import { HotspotSettingsComponent } from './VideoEditor/hotspot-settings/hotspot-settings.component';
import { VideoEditorComponent } from './VideoEditor/video-editor/video-editor.component';
import { MediaUploadComponent } from './VideoEditor/media-upload/media-upload.component';
import { VjsPlayerEditorComponent } from './VideoEditor/vjs-player-editor/vjs-player-editor.component';
import { MediaDialogComponent } from './VideoEditor/media-dialog/media-dialog.component';
import { PublishingComponent } from './VideoEditor/publishing/publishing.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { RegularVideoPlayerComponent } from './VideoEditor/regular-video-player/regular-video-player.component';
import { HotspotTemplateComponent } from './VideoEditor/hotspot-template/hotspot-template.component';
import { RegularVideoHotspotComponent } from './VideoEditor/regular-video-hotspot/regular-video-hotspot.component';
import { RegularVideoDialogComponent } from './VideoEditor/regular-video-dialog/regular-video-dialog.component';
import { RegularVideoPlayerInDialogComponent } from './VideoEditor/regular-video-player-in-dialog/regular-video-player-in-dialog.component';
import {HotspotDialogComponent} from "./VideoEditor/hotspot-dialog/hotspot-dialog.component";
import { ConfirmationDialogComponent } from './VideoEditor/confirmation-dialog/confirmation-dialog.component';
import { AdvancedViewerComponent } from './advanced-viewer/advanced-viewer.component';


const appRoutes: Routes = [
  { path: 'viewer',      component: ViewerComponent },
  {path:'viewer-login', component:LoginComponent},
  {path:'video-list', component:VideolistComponent},
  {path:'editor-login',component:LogineditorComponent},
  {path:'create-video',component:VideoformComponent},
  {path:'view-advanced-video',component:AdvancedViewerComponent},
  { path: '',
    pathMatch: 'full',
    component: MainpageComponent
  }
  //{ path: '**', component: PageNotFoundComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ViewerComponent,
    VjsPlayerComponent,
    HotspotComponent,
    HotspDirective,
    MainpageComponent,
    VideolistComponent,
    LogineditorComponent,
    VideoformComponent,
    VideopreviewComponent,
    HotspotDialogComponent,
    VideoEditorComponent,
    MediaUploadComponent,
    VjsPlayerEditorComponent,
    MediaDialogComponent,
    PublishingComponent,
    RegularVideoPlayerComponent,
    HotspotTemplateComponent,
    RegularVideoHotspotComponent,
    RegularVideoDialogComponent,
    RegularVideoPlayerInDialogComponent,
    HotspotSettingsComponent,
    ConfirmationDialogComponent,
    AdvancedViewerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true,
        useHash: true } // <-- debugging purposes only
    ),
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatRadioModule,
    MatToolbarModule,
    MatIconModule,
    MatTabsModule,
    MatTableModule,
    MatMenuModule,
    MatPaginatorModule,
    MatListModule,
    MatDialogModule,
    MatTooltipModule,
    MatGridListModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    DragDropModule
  ],
  providers: [UtilsService,AuthenticationService, HotspotService],
  entryComponents:[HotspotComponent, MediaDialogComponent, HotspotTemplateComponent, RegularVideoDialogComponent,HotspotDialogComponent,ConfirmationDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
