import { Component, OnInit } from '@angular/core';
import {ErrorStateMatcher} from "@angular/material";
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../services/authentication.service";
import {VideoService} from "../services/video.service";
import {StorageService} from "../services/storage.service";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})



export class LoginComponent implements OnInit {

  custom_matcher = new MyErrorStateMatcher();
  returnUrl: string;
  error = '';
  loginForm: FormGroup;
  videos;


  constructor(private router : Router,private route: ActivatedRoute,private fb: FormBuilder,private authenticationService: AuthenticationService, private videoService:VideoService, private dataService:StorageService) {
    this.createLoginForm();
    this.videoService.getVideos().subscribe(result=>{
      this.videos = result;
    })
  }

  login() : void {
    //
    /*if(this.loginForm.valid){

      let index = this.videos.findIndex((item,index)=>{
         return item.id === this.loginForm.value.video

       });

      if(this.videos[index].password===this.loginForm.value.password){
        this.loginForm.controls.password.setErrors({ invalid: false });
        this.videoService.setVideo(this.videos[index]);
        //this.videoService.video = this.videos[index];
        this.router.navigate(['/viewer']);
      }
      else{
       this.loginForm.controls.password.setErrors({ invalid: true })
      }


    }*/

    if(this.loginForm.valid){
      if(this.loginForm.value.password.localeCompare('video360%')!=0){
        this.loginForm.controls["password"].setErrors({ invalid: true });
      }
      else{

        console.log(this.loginForm.value.video);
        let videoId = this.loginForm.value.video;
        let video = this.videos.filter(v=>{
          return v._id.localeCompare(videoId)===0
        })[0];
        this.dataService.setScenes(video.scenes);
        this.router.navigate(['/view-advanced-video']);
      }
    }

  }

  createLoginForm(){
    this.loginForm = this.fb.group({
      video: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }
  ngOnInit() {
  }

 back(){
   this.router.navigate(['/'])
 }
}
