import { Component, OnInit } from '@angular/core';
import {VideoService} from "../../services/video.service";
import {Router} from "@angular/router";

import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.umd.js';
import {StorageService} from "../../services/storage.service";


@Component({
  selector: 'app-videolist',
  templateUrl: './videolist.component.html',
  styleUrls: ['./videolist.component.scss']
})
export class VideolistComponent implements OnInit {

  public videos;
  displayedColumns: string[] = ['name', 'scenes','date','actions'];
  nInteractions =0;

  constructor(private videoService:VideoService, private router:Router,private storageSerice: StorageService) {

    videoService.getVideos().subscribe(result=>{
      console.log(result);
      this.videos = result;

    });

  }

  removeVideo(videoId){
    console.log(videoId);
    this.videoService.deleteVideo(videoId).subscribe(result=>{

      this.videoService.getVideos().subscribe(result=>{
        console.log(result);
        this.videos = result;

      });
    });
  }
  addVideo(){
    this.storageSerice.clearStorage();

    this.router.navigate(['/create-video']);
  }

  editVideo(videoId){
    console.log(videoId);
    let video = this.videos.filter(v=>{
      return v._id.localeCompare(videoId)===0;
    })[0];
    this.storageSerice.clearStorage();
    this.storageSerice.setScenes(video.scenes);
    this.router.navigate(['/create-video']);

  }
  ngOnInit() {
  }

  back(){
    this.router.navigate(['/']);
  }

}
