/**
 * Created by asoadmin on 2020-09-28.
 */
import { Type } from '@angular/core';

export class HotspotItem {
  private id:string;

  constructor(public component: Type<any>, public data: any) {
    //console.log(data);
    this.id = data.id;
  }
}
