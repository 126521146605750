import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  static SERVER_URL = "https://isos360.lnu.se/WebService360";
  //static SERVER_URL = "http://localhost:3000";

  static HotspotTypes = [
    {id:"multichoice", name:"Multichoice"},
    {id:"free-text",name:"Free Text"},
    {id:"weblink",name:"Web link"}];


  constructor() { }

  static HotspotActions = [
    {id:"switch", name:"Switch to other scene"},
    {id:"none",name:"Do nothing"}];

  static MediaTypes = [
    {id:"spherical", name:"360 degrees"},
    {id:"regular",name:"Regular"}];

}
