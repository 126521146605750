import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {HotspotService} from "../../services/hotspot.service";
import {StorageService} from "../../services/storage.service";

@Component({
  selector: 'app-hotspot-template',
  templateUrl: './hotspot-template.component.html',
  styleUrls: ['./hotspot-template.component.scss']
})
export class HotspotTemplateComponent implements OnInit {

  @Input() data: any;
  //@Output() switchScene = new EventEmitter<any>();

  show = false;
  showQ = true;
  wrongAnswer=false;
  showFeedback = false;

  questionForm: FormGroup;
  freeTextForm: FormGroup;

  constructor(fb: FormBuilder, public hotspotService: HotspotService, private dataService:StorageService) {
    this.questionForm = fb.group({
      answers: new FormArray([])
    });

    this.freeTextForm = fb.group({
      freeText: ['',]
    });

  }

  ngOnInit() {
    console.log(this.data);
    this.addCheckboxes();
  }

  showQuestion() {
    //console.log("show question");
    this.show = true;
    this.showQ = false;

  }

  check() {
    if(this.data.type.indexOf("multichoice")!=-1){
      const selectedAnswers = this.questionForm.value.answers;
      const correctAnswers = this.data.answers.map(x => x.isCorrect);
      if (correctAnswers.toString().localeCompare(selectedAnswers.toString())===0) {
        this.wrongAnswer = true;
      }
      else {
        this.wrongAnswer = false;
      }

      this.showFeedback = true;
    }

  }

  checkWithStyle() {
    this.check();

    this.data.answers.forEach((item) => {
      if (item.isCorrect==='true') {
        item.color = 'rgba(103, 230, 152, 0.27)';
      }
      else {
        item.color = 'rgba(181, 5, 12, 0.35)';

      }

    });
  }
  private addCheckboxes() {
    if (this.data.answers) {
      this.data.answers.forEach(() => this.answersFormArray().push(new FormControl(false)));
    }
  }

  answersFormArray() {
    return this.questionForm.controls['answers'] as FormArray;
  }


  closeMultipleChoice() {

    setTimeout(() => {
      this.data.isShown = false;
    }, 3000);
    this.check();

    //remove hotspot from visualization
    //switch to another video scene

    if (this.data.endAction.localeCompare("switch")===0) {


      if(this.data.switchSceneA && this.wrongAnswer){

        this.swithVideo(this.data.videoSceneA,this.data.startTimeA)
      }
      else if(this.data.switchSceneB && !this.wrongAnswer){

        this.swithVideo(this.data.videoSceneB,this.data.startTimeB)
      }
      else{
        this.data.player.play();
      }

    }
    else {
      this.data.player.play();
    }
    //remove the hotspot from video player
    this.data.player.pnlmViewer.removeHotSpot(this.data.name);

  }

  private swithVideo(sceneId, videoTime) {
    //get scence video
    let scenes = this.dataService.scenes.getValue();

    let scene = scenes.filter((scene) => {
      return scene['id'].localeCompare(sceneId) === 0;
    })[0];



    //CHECK IF VIDEO IS REGULAR OR 360
    if (scene.media.type.localeCompare("regular") === 0) {
      //TODO:SWITCH TO REGULAR VIDEO
      console.log("SWITCHING TO REGULAR VIDEO!!!");
      this.dataService.switchScene.next({sceneId:sceneId,time:videoTime});

    }
    else {
      const sources = [{src: scene.media.src, type: 'video/mp4'}];
      this.data.player.src(sources);

      if (scene.hotspots != undefined) {
        this.hotspotService.createAdvancedHotspots(scene.hotspots);
      }
      if (videoTime != undefined) {
        this.data.player.currentTime(videoTime)
      }
      this.data.player.play();
    }
  }

  close(){
    setTimeout(() => {
      this.data.isShown = false;
    }, 3000);

    this.check();

    if (this.data.endAction.localeCompare("switch")===0) {
      this.swithVideo(this.data.videoSceneA,this.data.startTimeA)
    }
    else {
      this.data.player.play();
    }

    //remove the hotspot from video player
    this.data.player.pnlmViewer.removeHotSpot(this.data.name);

  }


}
