import {Component, ComponentFactoryResolver, Input, OnInit, ViewChild} from '@angular/core';
import {VjsPlayerComponent} from "../vjs-player/vjs-player.component";
import {HotspotInterface} from "./add-hotspot";
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {HotspotService} from "../services/hotspot.service";

@Component({
  selector: 'app-hotspot',
  templateUrl: './hotspot.component.html',
  styleUrls: ['./hotspot.component.scss']
})
export class HotspotComponent implements OnInit, HotspotInterface {
  @Input() data: any;
  show = false;
  showQ = true;
  wrongAnswer = false;

  options: FormGroup;
  freeTextForm: FormGroup;

  constructor(fb: FormBuilder, public hotspotService: HotspotService) {
    this.options = fb.group({
      answers: new FormArray([])
    });

    this.freeTextForm = fb.group({
      freeText: ['',]
    });

  }

  ngOnInit() {
    //console.log(this.data);
    this.addCheckboxes();
  }

  showQuestion() {
    //console.log("show question");
    this.show = true;
    this.showQ = false;
  }

  check() {
    if(this.data.type.indexOf("multichoice")!=-1){
      const selectedAnswers = this.options.value.answers;
      const correctAnswers = this.data.answers.map(x => x.correct);
      if (JSON.stringify(correctAnswers) === JSON.stringify(selectedAnswers)) {
        this.wrongAnswer = false;
      }
      else {
        this.wrongAnswer = true;
      }
    }

  }

  checkWithStyle() {
    this.check();
    this.data.answers.forEach((item) => {
      if (item.correct) {
        item.color = 'green';
      }
      else {
        item.color = 'red';

      }

    });
  }

  close() {

    setTimeout(() => {
      this.data.isShown = false;
    }, 3000);
    this.check();
    this.data.player.pnlmViewer.removeHotSpot(this.data.name);

    if (this.data.switch) {
      console.log("SWITCH VIDEO");
      if(this.data.condition.correct && !this.wrongAnswer){
        this.swithVideo(this.data.condition.correct.videoSrc,this.data.condition.correct.hotspots,this.data.condition.correct.videoTime)
      }
      else if(this.data.condition.incorrect && this.wrongAnswer){
        this.swithVideo(this.data.condition.incorrect.videoSrc,this.data.condition.incorrect.hotspots,this.data.condition.incorrect.videoTime)
      }
      else{
        this.data.player.play();
      }

    }
    else {
      this.data.player.play();
    }


  }

  private addCheckboxes() {
    if (this.data.answers) {
      this.data.answers.forEach(() => this.answersFormArray().push(new FormControl(false)));
    }
  }

  answersFormArray() {
    return this.options.controls.answers as FormArray;
  }

  private swithVideo(videoUrl, hotspotUrl, videoTime){
    const sources = [{ src: videoUrl, type: 'video/mp4' }];
    this.data.player.src(sources);
    if(hotspotUrl !=undefined){
      this.hotspotService.setHotspots(hotspotUrl);
    }
    if(videoTime != undefined){
      this.data.player.currentTime(videoTime)
    }
    this.data.player.play();
  }

}
